import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import './index.css';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';
import './i18n';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

ReactDOM.render(
  <RecoilRoot>
    <React.StrictMode>
      <Router>
      <App />
      </Router>
    </React.StrictMode>
  </RecoilRoot>,

  document.getElementById('root')
);